import { GET_SUBSCRIPTION,UPDATE_SUBSCRIPTIONS_SUB,UPDATE_ITEM_COUNT } from "./actionTypes";

const initialState = {
    subscriptions: [],updateSubscriptions:"",iCount:0
};

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_SUBSCRIPTION:
            return { ...state, subscriptions: action.payload };
        case UPDATE_SUBSCRIPTIONS_SUB:
            return { ...state, updateSubscriptions: action.payload };
        case UPDATE_ITEM_COUNT:
            return { ...state, iCount: action.payload };
    

        default:
            return state;
    }
}

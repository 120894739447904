import { PLACE_ORDER ,CREATE_SUBSCRIPTION_ORDER} from "./actionTypes";

const initialState = {
    checkout: [],checkoutSub:[]
};

export default function(state = initialState, action) {
    switch (action.type) {
        case PLACE_ORDER:
            return { ...state, checkout: action.payload };
        case CREATE_SUBSCRIPTION_ORDER:
            return { ...state, checkoutSub: action.payload };
        default:
            return state;
    }
}

import {
  CHECK_RESTAURANT_OPERATION_SERVICE,
  GET_RESTAURANTS_BASED_ON_CATEGORY,
  GET_RESTAURANTS_CATEGORIES,
  LOCATION_ENABLED,
  LOCATION_ENABLED_ERROR,
} from "./actionTypes";

const initialState = {
  is_operational: "",
  filtered_restaurants: [],
  restaurants_categories: [],
  locationEnabled: "",
  locationEnabledError: "",
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CHECK_RESTAURANT_OPERATION_SERVICE:
      return { ...state, is_operational: action.payload };
    case GET_RESTAURANTS_BASED_ON_CATEGORY:
      return { ...state, filtered_restaurants: action.payload };
    case GET_RESTAURANTS_CATEGORIES:
      return { ...state, restaurants_categories: action.payload };
    case LOCATION_ENABLED:
      return { ...state, locationEnabled: true, locationEnabledError: "" };
    case LOCATION_ENABLED_ERROR:
      return {
        ...state,
        locationEnabledError: action.payload,
        locationEnabled: false,
      };
    default:
      return state;
  }
}

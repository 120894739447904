import { GET_DELIVERY_ORDERS, PLAY_NEW_DELIVERY_NOTIFICATION } from "./actionTypes";

const initialState = {
    delivery_orders: [],
    playNewDeliveryNotification:false
};

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_DELIVERY_ORDERS:
            return { ...state, delivery_orders: action.payload };
        case PLAY_NEW_DELIVERY_NOTIFICATION:
            return { ...state, playNewDeliveryNotification: action.payload };
        default:
            return state;
    }
}

import * as firebase from "firebase/app";

import React, { Component } from "react";
import swal from "@sweetalert/with-react";


import Footer from "../Footer";
import Meta from "../../helpers/meta";
import Nav from "../Nav";
import PromoSlider from "./PromoSlider";
import { Redirect } from "react-router";
import RestaurantList from "./RestaurantList";
import { connect } from "react-redux";
import {
  getPromoSlides,
  hidePromoSlides,
  hidePopupImage,
} from "../../../services/promoSlider/actions";
import messaging from "../../../init-fcm";
import { saveNotificationToken } from "../../../services/notification/actions";
import { getSingleLanguageData } from "../../../services/languages/actions";
import { getUserNotifications } from "../../../services/alert/actions";
// import SliderCarausal from "../SliderCarausal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import LazyLoad from "react-lazyload";
import { NavLink } from "react-router-dom";
import Ink from "react-ink";
import axios from "axios";
import Fade from "react-reveal/Fade";
import ProgressBar from "./ProgressBar";
import { GET_RESTAURANTS_SLIDES_URL } from "../../../configs/index";

var settings = {
  autoplay: true,
  autoplaySpeed: 5000,
  dots: true,
  infinite: true,
  speed: 700,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ],
};
// const Comp = () =>

class Home extends Component {
  static contextTypes = {
    router: () => null,
  };
  state = {
    restaurants: [],
    data: [],
    popupByLocation: false,
  };



  changeRouteToRestaurantsCategories = (d) => {
    if (d.categories_ids) {
      const saveCategorySelectOptions = new Promise((resolve) => {
        localStorage.setItem(
          "categorySelectOptions",
          JSON.stringify(d.categories_ids)
        );
        resolve("categorySelectOptions Saved");
      });
      saveCategorySelectOptions.then(() => {
        // console.log("state", this.state);
        // console.log("props", this.props);
        // console.log("d.categories_ids.label", d.categories_ids);
        this.context.router.history.push("categories/stores");
      });
    }
  };
  async componentDidMount() {
    const { user } = this.props;
    //if currentLocation doesnt exists in localstorage then redirect the user to firstscreen
    //else make API calls
    this.getAllRestaurantSliders();
    if (localStorage.getItem("userSetAddress") !== null) {
      // this.context.router.history.push("/search-location");
      // console.log("Redirect to search location");
      // return <Redirect to="/search-location" />;
      let userSetAddress = JSON.parse(localStorage.getItem("userSetAddress"));
      this.props
        .getPromoSlides(userSetAddress.lat, userSetAddress.lng)
        .then((res) => this.setState({ popupByLocation: res }));

      const { user } = this.props;

      if (user.success) {
        this.props.getUserNotifications(user.data.id, user.data.auth_token);
      }
    } else {
      //call to promoSlider API to fetch the slides
    }

    if (user.success) {
      if (localStorage.getItem("enablePushNotification") === "true") {
        if (firebase.messaging.isSupported()) {
          // const today = moment().toDate();

          // console.log("TODAY", today);
          // const lastSavedNotificationToken = moment(localStorage.getItem("lastSavedNotificationToken"));
          // const days = moment(today).diff(lastSavedNotificationToken, "days");

          // console.log("DAYS", days);

          // const callForNotificationToken = isNaN(days) || days >= 5;

          // console.log(callForNotificationToken);
          // if (callForNotificationToken) {
          let handler = this.props.saveNotificationToken;
          messaging
            .requestPermission()
            .then(async function () {
              const push_token = await messaging.getToken();
              handler(push_token, user.data.id, user.data.auth_token);
              // localStorage.setItem("lastSavedNotificationToken", today);
            })
            .catch(function (err) {
              console.log("Unable to get permission to notify.", err);
            });
          // }
        }
      }
    }
    swal({
      content: (<a href="https://play.google.com/store/apps/details?id=xyz.appmaker.twdlgf"><img
        on
        src='./assets/img/newAppAlertGif.svg'
        style={{
          width: "100%"
        }}
      />
      </a>
      ),
      buttons: false,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.languages !== nextProps.languages) {
      if (localStorage.getItem("userPreferedLanguage")) {
        this.props.getSingleLanguageData(
          localStorage.getItem("userPreferedLanguage")
        );
      } else {
        if (nextProps.languages.length) {
          // console.log("Fetching Translation Data...");
          const id = nextProps.languages.filter(
            (lang) => lang.is_default === 1
          )[0].id;
          this.props.getSingleLanguageData(id);
        }
      }
    }
  }

  componentWillUnmount() {
    // navigator.serviceWorker.removeEventListener("message", message => console.log(message));
  }
  getAllRestaurantSliders = () => {
    axios.post(GET_RESTAURANTS_SLIDES_URL).then((response) => {
      if (response.data) {
        this.setState({
          data: response.data,
        });
      }
    });
  };
  render() {
    // if (window.innerWidth > 768) {
    //   return <Redirect to="/" />;
    // }
    let { data } = this.state;

    if (localStorage.getItem("userSetAddress") === null) {
      return <Redirect to="/search-location" />;
    }

    const userSetAddress = JSON.parse(localStorage.getItem("userSetAddress"));
    if (
      Object.keys(userSetAddress).length === 0 &&
      userSetAddress.constructor === Object
    ) {
      return <Redirect to="/search-location" />;
    }

    const { history, user, promo_slides } = this.props;
    return (
      <React.Fragment>
        <Meta
          seotitle={localStorage.getItem("seoMetaTitle")}
          seodescription={localStorage.getItem("seoMetaDescription")}
          ogtype="website"
          ogtitle={localStorage.getItem("seoOgTitle")}
          ogdescription={localStorage.getItem("seoOgDescription")}
          ogurl={window.location.href}
          twittertitle={localStorage.getItem("seoTwitterTitle")}
          twitterdescription={localStorage.getItem("seoTwitterDescription")}
        />

        <div
          className="height-100-percent bg-white mb-50  "
          style={{ position: "relative" }}
        >
          <Nav
            logo={true}
            active_nearme={true}
            disable_back_button={true}
            history={history}
            loggedin={user.success}
          />


          {!this.props.hidePromoSliderValue
            ? localStorage.getItem("showPromoSlider") === "true" && (
              <React.Fragment>
                {promo_slides &&
                  promo_slides.fullSlides &&
                  promo_slides.fullSlides.length > 0 && (
                    <Slider {...settings}>
                      {promo_slides.fullSlides.map((slide, index) => {
                        return (
                          <div key={index + "full"}>
                            {slide.url.indexOf("http://") === 0 ||
                              slide.url.indexOf("https://") === 0 ? (
                                <a
                                  href={slide.url}
                                  className="slider-wrapper__img-wrapper showShadowDark"
                                  key={slide.id}
                                  style={{
                                    position: "relative",
                                    userSelect: "none",
                                  }}
                                >
                                  {/* if customURL then use anchor tag */}
                                  <LazyLoad>
                                    <img
                                      src={slide.image}
                                      alt={slide.name}
                                      className={`slider-wrapper__img slider-cust-img  custom-promo-img`}
                                      style={{ width: "100%" }}
                                    />
                                  </LazyLoad>
                                  <Ink duration="500" hasTouch={true} />
                                </a>
                              ) : (
                                <NavLink
                                  to={"../" + slide.url}
                                  className="slider-wrapper__img-wrapper showShadowDark"
                                  key={slide.id}
                                  style={{ position: "relative" }}
                                >
                                  <LazyLoad>
                                    <img
                                      src={slide.image}
                                      alt={slide.name}
                                      className={`slider-wrapper__img slider-cust-img custom-promo-img`}
                                      style={{ width: "100%" }}
                                    />
                                  </LazyLoad>
                                  <Ink duration="500" hasTouch={true} />
                                </NavLink>
                              )}
                            <div className=" px-0 col-md-12 slider-wrapper secondary-slider-wrapper my-0 pb-0" />
                          </div>
                        );
                      })}
                    </Slider>
                  )}
                <br />
              </React.Fragment>
            )
            : null}
          {data.length !== 0 &&
            data.map((d, index) => {
              return (
                <div
                  key={index + "class"}
                  className="slider-wrapper__img-wrapper showShadowDark"
                >
                  <div style={{ position: "relative" }}>
                    <img
                      onClick={() => {
                        this.changeRouteToRestaurantsCategories(d);
                      }}
                      src={d.image}
                      alt={d.name}
                      className="slider-wrapper__img slider-cust-img"
                      style={{
                        height: "5.14286rem",
                        width: "5.14286rem",
                        borderRadius: "1rem",
                      }}
                    />
                    <div className="category-slider-name col">
                      <span>{d.name}</span>
                    </div>
                  </div>
                </div>
              );
            })}

          {this.props.showPopUpImage && this.state.popupByLocation ? (
            <div
              style={{
                top: "85%",
                zIndex: "10000",
                left: "1rem",
                position: "sticky",
              }}
            >
              <Fade duration={1000} right big>
                <div className="videoAd" style={{ width: "150px" }}>
                  <img
                    onClick={() => {
                      this.props.hidePopupImage(false);
                      this.context.router.history.push(
                        "/stores/amul-ice-cream-junagadh-ltkq3myqjqxaavr"
                      );
                    }}
                    src={"/assets/img/MilkBanner.png"}
                    className="main-video img-fluid"
                  />
                  <span
                    onClick={() => {
                      this.props.hidePopupImage(false);
                    }}
                    id="close"
                  >
                    X
                  </span>
                  {/* <video
                        className="main-video"
                        // onEnded={() =>{i=i+1}}
                        controlsList="nodownload"
                        disablePictureInPicture
                        controls
                        autoPlay={true}
                        muted
                      >
                        <source src={video[i]} />
                      </video> */}
                </div>
              </Fade>
            </div>
          ) : null}
          {!this.props.hidePromoSliderValue
            ? localStorage.getItem("showPromoSlider") === "true" && (
              <React.Fragment>
                {promo_slides &&
                  promo_slides.mainSlides &&
                  promo_slides.mainSlides.length > 0 && (
                    <PromoSlider
                      slides={promo_slides.mainSlides}
                      size={
                        promo_slides.mainSlides[0]["promo_slider"]["size"]
                      }
                    />
                  )}
              </React.Fragment>
            )
            : null}

          <ProgressBar />

          <RestaurantList
            user={user}
            slides={promo_slides.otherSlides}
            restaurant_info={this.props.restaurant_info}
            hidePromoSlides={(value) => this.props.hidePromoSlides(value)}
          />
          {/* className="auth-error ongoing-order-notify" */}
          {/* {!this.state.alreadyRunningOrders && (	<div  style={{position:"sticky",textAlign:"center",backgroundColor:"#282c3f",color:"#f4f4f5",zIndex:415456,bottom:"10%",padding:"4%"
}}>
											<DelayLink to="/my-orders" delay={500} className="ml-2">
												{localStorage.getItem("ongoingOrderMsg")}
												<i
													className="si si-arrow-right ml-1"
													style={{
														fontSize: "0.7rem",
													}}
												/>
												<Ink duration="50" />
											</DelayLink>
										
										</div>)} */}

          {/* {!this.state.alreadyRunningOrders&&this.state.lastDeliveredOrder && (	<div  style={{position:"sticky",textAlign:"center",backgroundColor:"#282c3f",color:"#f4f4f5",zIndex:415456,bottom:"10%",padding:"4%"
}}>
											<DelayLink to={this.state.reviewLink} delay={500} className="ml-2">
											Rate your last order
												<i
													className="si si-arrow-right ml-1"
													style={{
														fontSize: "0.7rem",
													}}
												/>
												<Ink duration="50" />
											</DelayLink>
										
										</div>)} */}

          <Footer active_nearme={true} />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  promo_slides: state.promo_slides.promo_slides,
  showPopUpImage: state.promo_slides.showPopUpImage,
  hidePromoSliderValue: state.promo_slides.hidePromoSliderValue,
  user: state.user.user,
  locations: state.locations.locations,
  languages: state.languages.languages,
  running_order: state.user.running_order,
  restaurant_info: state.items.restaurant_info,
});

export default connect(
  mapStateToProps,
  {
    getPromoSlides,
    saveNotificationToken,
    getSingleLanguageData,
    getUserNotifications,
    hidePromoSlides,
    hidePopupImage,
  }
)(Home);

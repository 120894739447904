export const GET_RESTAURANT_INFO = "GET_RESTAURANT_INFO";
export const GET_RESTAURANT_INFO_BY_ID = "GET_RESTAURANT_INFO_BY_ID";
export const GET_RESTAURANT_ITEMS = "GET_RESTAURANT_ITEMS";
export const GET_RESTAURANT_ITEMS_BY_FLAG = "GET_RESTAURANT_ITEMS_BY_FLAG";
export const RESET_ITEMS = "RESET_ITEMS";
export const RESET_INFO = "RESET_INFO";
export const SINGLE_ITEM = "SINGLE_ITEM";
export const GET_SUBSCRIPTIONS = "GET_SUBSCRIPTIONS";

export const SEARCH_ITEM = "SEARCH_ITEM";
export const SEARCH_RES_ITEM = "SEARCH_RES_ITEM";
export const CLEAR_SEARCH = "CLEAR_SEARCH";
export const CHANGE_LOADER = "CHANGE_LOADER";

import React, { Component } from "react";
import axios from "axios";
import { IS_PROGRESSBAR_URL } from "../../../../configs/index";
import { connect } from "react-redux";
import Confetti from "react-confetti";
import Zoom from "react-reveal/Zoom";

const Progress = ({ done }) => {
  const [style, setStyle] = React.useState({});
  setTimeout(() => {
    const newStyle = {
      opacity: 1,
      width: `${done}%`,
    };

    setStyle(newStyle);
  }, 200);
  return (
    <div
      className="progress mx-auto d-flex my-3"
      style={{ height: "30px", overflow: "visible !important" }}
    >
      <div className="progress-done position-relative" style={style}>
        <img
          className="position-absolute img-fluid-100"
          style={{ right: "-6%", height: "100px", zIndex: "9999 !important" }}
          src="assets/img/gif/popcorn.gif"
        />
        <div
          className="badge badge-pill badge-dark1"
          style={{ zIndex: "9 !important", fontSize: "12px" }}
        >
          {done}%
        </div>
      </div>
    </div>
  );
};

class ProgressBar extends Component {
  state = {
    orderpercentage: 0,
    showprogressbar: false,
    currentorder: 0,
    totalorderamount: 0,
    timerrun: false,
    recyclecount: true,
  };

  // componentWillReceiveProps(nextProps) {
  //   if (
  //     this.props.user.data.default_address !==
  //     nextProps.user.data.default_address
  //   ) {
  //     this.call();
  //     console.log("dsfasf", nextProps.user.data.default_address);
  //     console.log("dsfasf");
  //   }
  // }

  componentDidMount() {
    let { user } = this.props;
    if (user.success) {
      this.call();
    }
    setTimeout(() => {
      this.setState({ recyclecount: false });
    }, 10000);
  }
  call = () => {
    let { user } = this.props;
    let address = JSON.parse(localStorage.getItem("userSetAddress"));
    axios
      .post(IS_PROGRESSBAR_URL, {
        token: user.data.auth_token,
        longitude: address.lng,
        latitude: address.lat,
      })
      .then((response) => {
        // console.log(response.data);
        if (response.data.success) {
          this.setState({
            orderpercentage: parseInt(response.data.progress),
            showprogressbar: response.data.offerActive,
            currentorder: parseInt(response.data.target),
            totalorderamount: parseInt(response.data.totalorderamount),
            timerrun: true,
            recyclecount: true,
          });
        }
      })
      .catch((error) => this.setState({ error, isLoading: false }));
  };
  render() {
    return (
      <React.Fragment>
        <div className="col-12 mb-5">
          {this.state.showprogressbar && (
            <>
              {this.state.orderpercentage === 100 ? (
                <div
                  className="col-12 p-0 sticky-top border-0"
                  style={{ zIndex: 99999999 }}
                >
                  <Confetti
                    width={1000}
                    height={1000}
                    recycle={this.state.recyclecount}
                    style={{ inset: "-400% 0px 300% 0px", height: "" }}
                  />
                  <div className="card showShadow p-20">
                    <h5 className="mx-auto d-flex mt-2">
                      Your Movie ticket is here
                    </h5>
                    <Zoom duration={2000} right cascade>
                      <span className="mx-auto d-flex mt-2 flex-wrap">
                        Please call our Support Squad to claim movie coupon: +91
                        9833868686
                      </span>
                    </Zoom>
                  </div>
                </div>
              ) : (
                <div className="card showShadow ">
                  <h5 className="mx-auto d-flex mt-2">Get your Movie ticket</h5>
                  <a className="mx-auto lead font-size-default">
                    Reward on your path: {this.state.totalorderamount}/
                    {this.state.currentorder}
                  </a>
                  <img
                    className="position-absolute p-2 d-block img-fluid"
                    style={{ right: "3px", height: "63px", top: "9px" }}
                    src="assets/img/gif/gift.gif"
                  />
                  <Progress done={this.state.orderpercentage} />
                  {/* <Progress done={10} /> */}
                  <div />
                </div>
              )}
            </>
          )}
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect(
  mapStateToProps,
  {}
)(ProgressBar);

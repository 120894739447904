import {
  GET_PROMO_SLIDER,
  HIDE_PROMO_SLIDER,
  HIDE_IMAGE_POPUP,
} from "./actionTypes";
import { GET_PROMO_SLIDER_URL } from "../../configs";
import Axios from "axios";

export const getPromoSlides = (lat, lng) => (dispatch) => {
  return Axios.post(GET_PROMO_SLIDER_URL, {
    latitude: lat,
    longitude: lng,
  })
    .then((response) => {
      const promo_slides = response.data;
      dispatch({ type: GET_PROMO_SLIDER, payload: promo_slides });
      return response.data.popupByLocation;
    })
    .catch(function(error) {
      console.log(error);
    });
};

export const hidePromoSlides = (value) => (dispatch) => {
  dispatch({
    type: HIDE_PROMO_SLIDER,
    payload: value,
  });
};
export const hidePopupImage = (value) => (dispatch) => {
  dispatch({
    type: HIDE_IMAGE_POPUP,
    payload: value,
  });
};

import {
  GET_RESTAURANT_INFO,
  GET_RESTAURANT_INFO_BY_ID,
  GET_RESTAURANT_ITEMS,
  GET_RESTAURANT_ITEMS_BY_FLAG,
  RESET_INFO,
  RESET_ITEMS,
  SINGLE_ITEM,
  SEARCH_ITEM,
  CLEAR_SEARCH,
  SEARCH_RES_ITEM,CHANGE_LOADER,
  GET_SUBSCRIPTIONS
} from "./actionTypes";

const initialState = {
  restaurant_info: [],
  restaurant_items: [],
  single_item: [],loader:true,subscription_plans:[]
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LOADER:
      return { ...state, loader: action.payload };  
       case SEARCH_RES_ITEM:
      return { ...state, restaurant_items: action.payload };
    case GET_RESTAURANT_INFO:
      return { ...state, restaurant_info: action.payload };
    case GET_RESTAURANT_INFO_BY_ID:
      return { ...state, restaurant_info: action.payload };
    case GET_RESTAURANT_ITEMS:
      return { ...state, restaurant_items: action.payload };
    case GET_RESTAURANT_ITEMS_BY_FLAG:
      return { ...state, restaurant_items_by_flag: action.payload };
    case RESET_ITEMS:
      return { ...state, restaurant_items: action.payload };
    case RESET_INFO:
      return { ...state, restaurant_info: action.payload };
    case SINGLE_ITEM:
      return { ...state, single_item: action.payload };
    case SEARCH_ITEM:
      return { ...state, restaurant_items: action.payload };
    case CLEAR_SEARCH:
      return { ...state, restaurant_items: action.payload };
    case GET_SUBSCRIPTIONS:
      return { ...state, subscription_plans: action.payload };
    default:
      return state;
  }
}

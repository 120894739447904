import React, { Component } from "react";

import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { checkUserRunningOrder } from "../../../services/user/actions";
import { GET_LAST_DELIVERY } from "../../../configs";
import Axios from "axios";
class Footer extends Component {
  state = {
    active_nearme: false,
    active_explore: false,
    active_cart: false,
    active_account: false,
    active_alerts: false,
    unread_alerts_count: null,
    lastDeliveredOrder: false,
    reviewLink: "",
    alreadyRunningOrders: false,
  };
  componentWillReceiveProps(nextProps) {
    if (this.props.running_order) {
      // this.props.checkUserRunningOrder(this.props.user.data.id, this.propsuser.data.auth_token)

      this.setState({ alreadyRunningOrders: this.props.running_order });
      return true;
    }
  }
  componentDidMount() {
    if (this.props.active_nearme === true) {
      this.setState({ active_nearme: true });
    }
    if (this.props.active_explore === true) {
      this.setState({ active_explore: true });
    }
    if (this.props.active_cart === true) {
      this.setState({ active_cart: true });
    }
    if (this.props.active_account === true) {
      this.setState({ active_account: true });
    }
    if (this.props.active_alerts === true) {
      this.setState({ active_alerts: true });
    }

    let { user } = this.props;

    if (user.length !== 0) {
      this.getLastDelivery();

      this.props.checkUserRunningOrder(user.data.id, user.data.auth_token);
    }
  }
  getLastDelivery = () => {
    Axios.post(GET_LAST_DELIVERY, {
      token: this.props.user.data.auth_token,
    })
      .then((response) => {
        if (response.data.length !== 0) {
          this.setState({
            lastDeliveredOrder: true,
            reviewLink: "/rate-and-review/" + response.data[0].id,
          });
        }
        this.setState({ load: false });
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  render() {
    const { cartTotal, alertUnreadTotal } = this.props;
    return (
      <React.Fragment>
        {/* footer-desktop class = offset-md-7 col-md-5  */}
        {this.props.running_order ? (
          <NavLink
            to="/my-orders"
            className=" p-md-2 text-white"
            style={{
              backgroundColor: "#282c3f",
              color: "#f4f4f5",
              zIndex: 415456,
              position: "fixed",
              bottom: "82px",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              padding: "2%",
              textAlign: "center",
            }}
          >
            <span> {localStorage.getItem("ongoingOrderMsg")}</span>
          </NavLink>
        ) : this.state.lastDeliveredOrder ? (
          <NavLink
            to={this.state.reviewLink}
            className=" p-md-2 text-white"
            style={{
              backgroundColor: "#282c3f",
              color: "#f4f4f5",
              zIndex: 415456,
              position: "fixed",
              bottom: "82px",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              padding: "2%",
              textAlign: "center",
            }}
          >
            <span> Rate your last order</span>
          </NavLink>
        ) : null}

        <div className="content pt-10 py-2 font-size-xs clearfix footer-fixed d-flex justify-content-around">
          <NavLink to="/" className="col footer-links px-2 col-md-1 py-1">
            <i className="si si-pointer fa-2x" /> <br />
            <span
              className={this.state.active_nearme ? "active-footer-tab" : ""}
            >
              {this.state.active_nearme ? (
                localStorage.getItem("footerNearme")
              ) : (
                <span> {localStorage.getItem("footerNearme")}</span>
              )}
            </span>
          </NavLink>
          <NavLink to="/alerts" className="col footer-links px-2 col-md-1 py-1">
            {alertUnreadTotal > 0 ? (
              <span
                className="cart-quantity-badge"
                style={{ backgroundColor: localStorage.getItem("storeColor") }}
              >
                {alertUnreadTotal}
              </span>
            ) : null}
            <i className="si si-bell fa-2x" /> <br />
            <span
              className={this.state.active_alerts ? "active-footer-tab" : ""}
            >
              {this.state.active_alerts ? (
                localStorage.getItem("footerAlerts")
              ) : (
                <span> {localStorage.getItem("footerAlerts")}</span>
              )}
            </span>
          </NavLink>
          <NavLink
            to="/explore"
            className="col footer-links px-2 col-md-1 py-1"
          >
            <i className="si si-magnifier fa-2x" /> <br />
            <span
              className={this.state.active_explore ? "active-footer-tab" : ""}
            >
              {this.state.active_explore
                ? localStorage.getItem("footerExplore")
                : localStorage.getItem("footerExplore")}
            </span>
          </NavLink>
          <NavLink to={"/cart"} className="col footer-links px-2 col-md-1 py-1">
            <i className="si si-bag fa-2x" /> <br />
            <span className={this.state.active_cart ? "active-footer-tab" : ""}>
              {this.state.active_cart
                ? localStorage.getItem("footerCart")
                : localStorage.getItem("footerCart")}
              {cartTotal.productQuantity > 0 ? (
                <span
                  className="cart-quantity-badge"
                  style={{
                    backgroundColor: localStorage.getItem("storeColor"),
                  }}
                >
                  {cartTotal.productQuantity}
                </span>
              ) : null}
            </span>
          </NavLink>
          <NavLink
            to="/my-account"
            className="col footer-links px-2 col-md-1 py-1"
          >
            <i className="si si-user fa-2x" /> <br />
            <span
              className={this.state.active_account ? "active-footer-tab" : ""}
            >
              {this.state.active_account
                ? localStorage.getItem("footerAccount")
                : localStorage.getItem("footerAccount")}
            </span>
          </NavLink>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  cartTotal: state.total.data,
  alertUnreadTotal: state.alert.alertUnreadTotal,
  running_order: state.user.running_order,
});

export default connect(
  mapStateToProps,
  {
    checkUserRunningOrder,
  }
)(Footer);
